import styled from 'styled-components'

import structure from '@/structure'
import { breakpoints } from '@/styles'
import { toUnit } from '@/utils/styles'

const MainWrapper = styled.main`
  margin-top: ${toUnit(-structure.header.height)};
  flex: 1;

  ${props =>
    props.top &&
    `
    padding-top: ${toUnit(structure.header.height)};
  `}

  @media (max-width: ${breakpoints.phoneMax}) {
    margin-top: ${toUnit(-structure.header.heightMobile)};
    ${props =>
      props.top &&
      `
      padding-top: ${toUnit(structure.header.heightMobile)};
    `}
  }
`
export default MainWrapper
